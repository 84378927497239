#content-wrapper {
  padding-bottom: 3rem;
  #index &{padding-bottom:0;}

  .page-header {
    margin-bottom: 3rem;

    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
    }
  }
}

hr {
  &:not(.alert-divider) {
    color: var(--bs-gray-500);
  }
}

;@import "sass-embedded-legacy-load-done:3731";