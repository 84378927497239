.product-flags {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding:0 0.25rem 0.25 0rem;
}

.badge {
  margin:0 0.25rem 0.25 0rem;
  background-color: var(--bs-secondary);

  @include media-breakpoint-down(md) {
    padding: 0.25rem;
    font-size: 0.75rem;
  }

  &.discount {
    color: var(--bs-white);
    background-color: var(--bs-secondary);
    border: 1px solid var(--bs-secondary);
    font-weight:700;
  }
}

;@import "sass-embedded-legacy-load-done:3559";