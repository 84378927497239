.inline-items {
  display: flex;
  flex-wrap: nowrap;
}

@each $breakpoint, $container-max-width in $container-max-widths {
  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    .container--limited-#{$breakpoint} {
      max-width: $container-max-width;
    }

    .w-#{$breakpoint}-auto {
      // stylelint-disable-next-line declaration-no-important
      width: auto !important;
    }

    .w-#{$breakpoint}-100 {
      // stylelint-disable-next-line declaration-no-important
      width: 100% !important;
    }
  }
}

.text-gray {
  color: var(--bs-gray-700);
}

;@import "sass-embedded-legacy-load-done:3541";