.layout-left-column {
  #left-column {
    padding-right: 3rem;
  }
}

.layout-left-column,
.layout-full-width {
  .products-selection {
    margin-bottom: 1.25rem;

    .select-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0.75rem 1.25rem;
      text-align: left;
      border: 1px solid $gray-300;
    }

    &s-filters {
      align-items: center;
    }

    .total-products {
      p {
        margin-bottom: 0;
      }
    }

    .sort-by-row {
      align-items: center;
    }
  }
}

;@import "sass-embedded-legacy-load-done:3654";