$component-name: main-menu;

@keyframes from-left {
  from { transform: translateX(-100%); }

  to { transform: translateX(0); }
}

@keyframes from-right {
  from { transform: translateX(100%); }

  to { transform: translateX(0); }
}

.#{$component-name} {
  &__mobile {
    position: relative;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    .menu {
      position: relative;
      left: 100%;
      display: none;
      width: 100%;
      max-width: 25rem;
      padding: 0.5rem;
      transition: 0.25s ease-out;

      li:not(.h5),
      a {
        color: var(--bs-gray-700);
      }

      a {
        display: block;
        padding: 0.5rem;
      }

      .menu--current,
      &.menu--current {
        left: 0;
        display: block;
      }

      .menu--fromLeft,
      &.menu--fromLeft {
        animation: from-left 0.25s;
      }

      .menu--fromRight,
      &.menu--fromRight {
        animation: from-right 0.25s;
      }

      .menu--parent,
      &.menu--parent {
        left: -100%;
        display: none;
        opacity: 0;
      }

      &--childrens {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &__title {
    padding: 0 0.5rem;
    font-size: 1.25rem;
    color: var(--bs-gray-800);
  }

  &__offcanvas {
    overflow: hidden;
    border-right:0 !important;

    .contact-link {
      display: block;
      margin-bottom: 1rem;
    }
  }

  &__additionnals {
    flex-shrink: 0;
    padding-bottom: 0;
  }

  &__tree {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  &__back-button .btn {
    display: flex;
    align-items: center;

    .material-icons {
      margin-right: 0.5rem;
    }
  }

  &__toggle-child {
    padding: 0.5rem;
  }
}

;@import "sass-embedded-legacy-load-done:3568";