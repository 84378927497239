$component-name: category-tree;

#js-product-list-header,#product-list-header {
  --#{$component-name}-title-color: #{$gray-100};
  --#{$component-name}-title-size: 1.5rem;
  --#{$component-name}-title-spacing: 1rem;
  --#{$component-name}-subtitle-color: #{$gray-100};
  --#{$component-name}-subtitle-size: 1.25rem;
  --#{$component-name}-subtitle-spacing: 0.75rem;
  --#{$component-name}-border-bottom: 1px solid #{$gray-200};
}

.#{$component-name} {
  padding-bottom: 1rem;
  margin-bottom: 2rem;

  &__child {
    padding: 0;
    border: none;
  }

  &__title {
  	display:none;
    padding: 0;
    margin-bottom: var(--#{$component-name}-title-spacing);
    font-size: var(--#{$component-name}-title-size);
    border: none;

    &__link {
      display: block;
      font-weight: 600;
      line-height: 2rem;
      color: var(--#{$component-name}-title-color);
    }
  }
  .list-group-item{
  	background:transparent;	
  }

  &__item {
  	display:inline-block;
  	padding: 0 var(--#{$component-name}-subtitle-spacing);
  	@include media-breakpoint-mobile(){margin-bottom:1rem;}
    &__link {
      display: block;
      font-size: var(--#{$component-name}-subtitle-size);
      font-weight: 400;
      border-bottom:1px solid transparent;	
      transition : border 0.5s cubic-bezier(.8,0,1,1) 0s !important;
      &.current_category,&:hover{
      	border-color:$secondary;	
      }
      .category-cover+.category-text &, .category-cover #_mobile_category_desc &{
      	color: var(--#{$component-name}-subtitle-color);
      	&.current_category,&:hover{
      		border-color:$gray-100;	
      	}
    	}
    }

    &__header {
      &--parent {
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
          padding: 0;
        }
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:3641";