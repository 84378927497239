/* carousel navigation styles */
.sw-nav, .n-hover:hover .sw-nav {
	display: block;
	position: absolute;
	left: 0px;
	top: 50%;
	width: 45px;
	height: 45px;
	margin-top: -25px;
	z-index: 10;
	cursor: pointer;
	text-align: center;
	opacity: 1;
	outline: none;
	transition: all 0.1s ease-in-out;
	&:before{
		font-family:Material Icons;
		content:'keyboard_arrow_left';
		color:$secondary;
		font-size: 3rem;
		display: inline-block;
		@include media-breakpoint-mobile(){
			font-size:2rem;
		}
	}
	&.prev:before{
				content:'keyboard_arrow_left';
	}
	&.next:before{
				content:'keyboard_arrow_right';
	}
}
.sw-nav.next, .n-hover:hover .sw-nav.next {
	right: -13px;
	left: auto;
	opacity: 1;
}
.sw-nav.prev , .n-hover:hover .sw-nav.prev  {
	left: -16px;
}

.n-hover .sw-nav {
	left: 0;
	right: auto;
	opacity: 0;
}
.n-hover .sw-nav.next {
	right: 0;
	left: auto;
	opacity: 0;
}
.sw-pgn, .p-hover:hover .sw-pgn {
	margin:0 auto ;
	margin-top: 15px;
	display: block;
	text-align: center;
	z-index: 10;
	transition: all 0.1s ease-in-out;
	opacity: 1;
}
.sw-pgn{
	  position: absolute;
	  bottom: 10px;
    left: 0;
    right: 0;
    top: auto;
  .sw-pgn-bullet{
  	background:$white;
  	opacity: 0.5;
  	border-radius: 0;
    border: 0;
    width: 38px;
    height: 3px;
  	&.active{
  		opacity: 1;
  	}
  }
}
.sw-pgn-bullet {
	display: inline-block;
	width: 14px;
	height: 14px;
	background: #333;
	border: 2px solid #FFF;
	border-radius: 100%;
	margin: 0 3px;
	opacity: 0.2;
	outline: none;
	cursor: pointer;
}
.sw-pgn-bullet.active {
	opacity: 1;
}
.p-hover .sw-pgn {
	bottom: 0;
	opacity: 0;
}
.cb .cb-wrapper .hidden {
	display: none;
}

.cb-item {
	text-align: center;
	position: relative;
}
.cb-item-content{
	position: relative;
}
.cb-item-content .cb-img {
	display: inline-block;
	max-width: 100%;
	height: auto;
}
.cb-item-content .cb-img.secondary-image,
.cb-item-content:hover .cb-img.primary-image {
	display: none;
}
.cb-item-content:hover .cb-img.secondary-image {
	display: inline-block;
}
.html-over .custom-html {
	display: table;
	margin: auto;
	position: absolute;
	width: 100%;
	top: 0; left: 0; bottom: 0; right: 0;
}
.img-rb .cb-item-content img {
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}
/* carousels */
.cb-wrapper .cb-carousel {
	visibility: hidden;
	max-height: 50px;
}
.cb-wrapper .cb-carousel.swiper-container-horizontal {
	visibility: visible;
	max-height: none;
}
/* accordion */
.cb-wrapper.type-4 {
	margin-top: 1px;
}
.cb-wrapper.type-4 .cb-item {
	width: 100%;
}
.cb-wrapper.type-4 .cb-item-title {
	text-align: left;
	padding: 10px;
	border: 1px solid #D0D5DB;
	margin-top: -1px;
	cursor: pointer;
}
.cb-wrapper.type-4 .cb-item-title:before {
	content: '+';
	font: bold 1.2em/1 Arial;
	color: #AAB2BD;
	margin-right: 5px;
}
.cb-wrapper.type-4 .cb-item.active .cb-item-title:before {
	content: '−';
}

/* grid values for 480 px. They are not present in BS 3 */
@media (max-width: 479px) {
	[class*=".col-xxs-"]{ float: left; }
	.col-xxs-1  { width: 8.3%; }
	.col-xxs-2  { width: 16.6%; }
	.col-xxs-3  { width: 25%; }
	.col-xxs-4  { width: 33.3%; }
	.col-xxs-5  { width: 41.8%; }
	.col-xxs-6  { width: 50%; }
	.col-xxs-7  { width: 58.3%; }
	.col-xxs-8  { width: 66.6%; }
	.col-xxs-9  { width: 75%; }
	.col-xxs-10 { width: 83.3%; }
	.col-xxs-11 { width: 91.6%; }
	.col-xxs-12 { width: 100%; }
}

/* special classes used in layout */
.cb .compact-gutters-container {
	margin: 0 -5px;
}
.cb .compact-gutters {
	padding: 0 5px 9px 5px;
}
.box-shadow, .img-box-shadow img {
	box-shadow: 1px 1px 7px 0 rgba(0, 0, 0, 0.15);
}
/* since 2.9.7 */

.ratio-1x1 {
    --bs-aspect-ratio:calc(805 / 452 * 100%);
}


.home-slider{
	    position: relative;
		.cb-item-content{
			background:$body-color;
			img{
				opacity:0.6;
				/*height:100vh;
				object-fit:cover;*/
				width:100%;
				@include media-breakpoint-mobile-portrait(){
						height:inherit;
						object-fit:inherit;
				}
			}
		}
		.html-over .custom-html{
			  max-width: 540px;
			  top:50%;
			  transform:translateY(-42%);
		    bottom: auto;
		    left: 16%;
    		right: auto;
    		text-align: left;
		    line-height: 1.1;
		    color: $white;
		    @include font-size(2rem);
		    b,strong{
			    @extend .h1;
			    color: $white;
			    font-weight:400;
			    @include font-size(3.4375rem);
		  	}
		  	p{margin-bottom:0;}
		   	@include media-breakpoint-mobile-portrait(){
		   		 left: 5%;
		   	} 	
		}
		.sw-nav{
			&:before{color:$white;}
			&.next{
					right: 0px;
			}
			&.prev{
					left: 0px;
			}
		}
}

.home-cat .cb-item{
		@extend .col-with-padding;
		@include media-breakpoint-mobile-portrait(){
				padding-right:0;
				padding-left:0;
				margin-bottom:1rem;
		}	
		.cb-item-content{
			background:$body-color;
			overflow:hidden;
			img{
				opacity:0.6;
				width:100%;	
				transition:all .5s ease-out;
			}
			@include media-breakpoint-desktop(){
				&:hover img{transform:scale(1.1);}
			}
		}
		.custom-html{
			text-align:left;
			top:auto;
			bottom:1.5rem;
			padding-left:1.5rem;
			color:$white;
			h2{
				font-weight:600;
				color:$white;
				margin-bottom:0.1rem;	
			}
			p{
				@include font-size(1.25rem);	
			}
			em{
				font-style:normal;
				color:$secondary;
				@extend .btn;	
				@extend .btn-secondary;	
				border-color:transparent;
			}
			
		}
}

.cb-item.video{

		.custom-html>p{
			@extend .ratio;
			@extend .ratio-1x1;
			/*@include media-breakpoint-mobile-portrait {
				&:before {
    			padding-top: 56.25%; //embed-responsive-16by9
				}	
			}*/
			margin-bottom:0;
			p{margin-bottom:0;}
			video{
				/*extend .embed-responsive-item;*/
				/*@include media-breakpoint-up(sm) {
					object-fit: cover;
				}*/
			}
		}
		/*@include media-breakpoint-mobile-portrait {
			margin-top: 0rem;	
		}*/
	}
	

.box_home{
	position:relative;
	&:before{
	  content: url(../img/jambon-entier.png);
    position: absolute;
    bottom: 0;
    left: 0;
    @include media-breakpoint-mobile(){
    	content:"";	
    }
  }	
}

.displayHome {
	/*@extend .slick__arrow-outside;*/
	@extend .offset-xl-2;
	@extend .col-xl-8;
	@extend .col-12;
	padding:0px 20px;
	.sw-nav{
		&.prev{
			left:-5px;	
		}
		&.next{
			right:-5px;	
		}
	}
	@include media-breakpoint-mobile(){
		.cb-item-content{
			background:$body-color;
			overflow:hidden;
			img{
				opacity:0.8;
				width:100%;	
				transition:all .5s ease-out;
			}
		}
		/*.slick-arrow{
			padding-bottom:4rem;	
		}*/
	}
}

.home-recipe{
	.cb-item.video{
		padding-left: calc($grid-gutter-width * .5);
		@include media-breakpoint-mobile-portrait(){
				padding-left:0;
		}	
	}
	.cb-item:not(.video){
		padding-right: calc($grid-gutter-width * .5);
		@include media-breakpoint-mobile-portrait(){
				padding-right:0;
		}	
		.custom-html{
			background:$gray-100;
			color:$gray-500;
			padding:1.3rem 0 0.8rem;
			h3{
					@extend .display-6;
			}
		}
	}
}

.home-presentation{
	  background:$gray-100;
	  padding:2rem 0;
	  position:relative;
		.cb-item-content{
		@extend .row;
		>img{
			@extend .offset-xl-2;
			@extend .col-xl-4;
			@extend .col-md-6;
			align-self: center;
			@include media-breakpoint-mobile-portrait(){
				margin-bottom:1rem;
				padding-left:0;
				padding-right:0;	
			}
		}
		>.custom-html{
				@extend .col-xl-4;
				@extend .col-md-6;
				align-self: center;
				text-align:left;
				padding-left:2.5rem;
				@include media-breakpoint-mobile-portrait(){
					padding-left:calc(var(--bs-gutter-x) * 0.5);
				}
				h1{
					font-weight:400;	
				}
		}
	}	
	&:after{
	  content: url(../img/BB.png);
    position: absolute;
    bottom: 0;
    right: calc($grid-gutter-width * .5);
    @include media-breakpoint-mobile() {
    	content:'';	
    }
  }
  @include media-breakpoint-mobile-portrait(){
			padding:0 0 1rem;
	}	
}

.home-tabs{
	h2{
		@extend .section-title;
	}
	ul{
		display:flex;
		justify-content:center;	
	}	
	.nav-item{
		padding:var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);	
	}
	.nav-link{
		font-weight:400;
		color:$body-color;
		font-size:1.125rem;
		padding:0;
		cursor: pointer;
		&.active{
			font-weight:700;	
			border-bottom:0;
			text-decoration: underline;
		}
	}
}

.home-tab-content{
	  padding:1rem 0;
		.cb-item-content{
		display:flex;
		flex-wrap:wrap;
		align-items:stretch;
		>img{
			flex: 0 0 auto;
			width:60%;
			@include media-breakpoint-up(xl) {
					margin-left:12.5%;
					width: 45%;
			}
			@include media-breakpoint-up(xxl) {
					width: 49%;
			}
		}
		>.custom-html{
				@extend .col-xl-3;
				@extend .col-sm-6;
				@extend .col-12;
				text-align:left;
				padding-left:2rem;
				overflow: scroll;
    		overflow-x: hidden;
    		max-height: 423px;
    		width: 30%;
    		h3{
    			font-weight:400;
    			margin-top: 1.5rem;
    			margin-bottom:0.8rem;
    		}
    		p{
    			color:$gray-500;	
    			font-size:$small-font-size;
    			margin-bottom:0.8rem;
    		}
    		a{
    			display: inline-block;
    			padding-right: 1.5rem;
    			text-transform:uppercase;
    			font-weight:700;
    			font-size:$small-font-size;
    			color:$primary;
    		}
    		@include media-breakpoint-up(xl) {
					width: 25%;
					max-height:423px;
				}
    		@include media-breakpoint-up(xxl) {
					width: 21%;
					max-height: 576px;
				}
		}
		@include media-breakpoint-mobile-portrait(){
				flex-direction:column;
				>img{
					width:100%;	
				}
				.custom-html{
					width: 100%;
				}
		}
	}	
}

/*displayLeftColumn **/
.displayLeftColumn .box-contact{
	.cb-item{
		margin-bottom:2rem;
		.custom-html{
			>p:first-child{
				/*@extend .card-header ;**/
			   font-weight:700;
   			/* @include font-size($h2-font-size);*/
			   color:$secondary;
			   border:0;
				 padding-left: 0;
				 padding-bottom: 0;
				 margin-bottom:1rem;
				&:after{
			   		content:" ";
			   		width:100%;
			   		border-bottom:1px solid var(--bs-gray-300);
			   		display: block;
			   		padding-top:0.5rem;
	   		}
    		.material-icons{
    			  font-size: 30px;
    				vertical-align: middle;
    				margin-right: 0.5rem;
    		}
			}
			a{
				text-decoration:underline;
				text-underline-position:under;
			}
			
		}
	}
}

;@import "sass-embedded-legacy-load-done:3718";