$component-name: account-menu;

.#{$component-name} {
  display: flex;
  flex-direction: column;

  > a {
    display: block;

    /* Width to properly override hooked modules */
    width: 100%;
    padding: 0.4rem 0.5rem 0.8rem;

    &:hover,
    &.active {

      .link-item {
        &,
        i {
          color: var(--bs-primary);
        }
      }
    }

    &.#{$component-name}--signout {
      .link-item {
        color: var(--bs-danger);

        &,
        i {
          color: var(--bs-danger);
        }
      }
    }
  }

  .link-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    color: var(--bs-body-color);
    transition: all 0.25s ease-out;

    i {
      margin-right: 0.75rem;
    }
  }

  &__back {
    display: block;
    margin-bottom: 1rem;
  }
}

;@import "sass-embedded-legacy-load-done:3662";