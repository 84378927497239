@use "sass:math";

$component-name: noui;

.#{$component-name} {
  /* Base;
   *
   */
  &-pips,
  &-pips * {
    box-sizing: border-box;
  }

  &-pips {
    position: absolute;
    color: $nouislider-pips-color;
  }

  /* Values;
    *
    */
  &-value {
    position: absolute;
    text-align: center;
    white-space: nowrap;
  }

  &-value-sub {
    font-size: $nouislider-pips-value-font-size;
    color: $nouislider-pips-value-color;
  }

  /* Markings;
    *
    */
  &-marker {
    position: absolute;
    background: $nouislider-marker-background-color;
  }

  &-marker-sub {
    background: $nouislider-marker-sub-background-color;
  }

  &-marker-large {
    background: $nouislider-marker-large-background-color;
  }

  /* Horizontal layout;
    *
    */
  &-pips-horizontal {
    top: 100%;
    left: 0;
    width: 100%;
    height: $nouislider-pips-horizontal-height;
    padding: $nouislider-pips-horizontal-spacing;
  }

  &-value-horizontal {
    transform: translate(-50%, 50%);

    &-rtl & {
      transform: translate(50%, 50%);
    }
  }

  &-marker-horizontal.#{$component-name}-marker {
    width: $nouislider-marker-horizontal-width;
    height: $nouislider-marker-horizontal-height;
    margin-left: -#{math.div($nouislider-marker-horizontal-width, 2)};
  }

  &-marker-horizontal.#{$component-name}-marker-sub {
    height: $nouislider-marker-sub-horizontal-height;
  }

  &-marker-horizontal.#{$component-name}-marker-large {
    height: $nouislider-marker-large-horizontal-height;
  }

  /* Vertical layout;
    *
    */
  &-pips-vertical {
    top: 0;
    left: 100%;
    height: 100%;
    padding: $nouislider-pips-vertical-spacing;
  }

  &-value-vertical {
    padding-left: 25px;
    transform: translate(0, -50%);

    &-rtl & {
      transform: translate(0, 50%);
    }
  }

  &-marker-vertical.#{$component-name}-marker {
    width: $nouislider-marker-vertical-width;
    height: $nouislider-marker-vertical-height;
    margin-top: -#{math.div($nouislider-marker-vertical-height, 2)};
  }

  &-marker-vertical.#{$component-name}-marker-sub {
    width: $nouislider-marker-sub-vertical-width;
  }

  &-marker-vertical.#{$component-name}-marker-large {
    width: $nouislider-marker-large-vertical-width;
  }
}

;@import "sass-embedded-legacy-load-done:3601";