$component-name: product-miniature;

.#{$component-name} {
  .card {
    height: 100%;
  }

  & &__infos {
    position: relative;
    z-index: 90;
    padding: 0;

    &__top,
    &__bottom {
      padding: 0.25rem 0rem 0rem;
      background-color: var(--bs-white);
    }

    &__top {
      padding-top: 0.5rem;
    }

    &__bottom {
      margin-bottom: 1rem;
    }
  }

  & &__title {
    margin: 0;
  }

  & &__image {
    height: auto;
  }

  & &__quickview {
    display: flex;
    margin: auto;
  }

  & &__regular-price {
    text-decoration: line-through;
  }

  & &__prices {
    display: flex;
  }

  & &__price {
    @extend .me-2;
  }
}

;@import "sass-embedded-legacy-load-done:3557";