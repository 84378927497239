.social-sharing {
  display: flex;
  align-items: center;

  > span {
    display: inline-block;
    margin-right: 0.5rem;
  }

  ul {
    display: flex;
    align-items: center;
    margin: 0;

    li {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
      background-position: center;
      background-size: contain;

      &::before {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: contain;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        font-size: 0;
      }
    }
  }
}

.social-share-btn{
  display: inline-block;
  &:not(use) >svg{
  	width: 33px;
  	 height:33px;
  	 fill:$white;
  }
  &:hover{
  	svg{fill:$primary;}	
  }
  &.facebook:hover svg{fill:#1877F2;}
  &.twitter:hover svg{fill:#1DA1F2;}
  &.instagram:hover svg{fill:#C42D91;}
  &.youtube:hover svg{fill:#f00;}
  &.pinterest:hover svg{fill:#E60023;}
  &.linkedin:hover svg{fill:#0274b3;}
}

#block-myaccount-infos {
  .myaccount-title a {
    color: var(--bs-gray-900);
  }
}

;@import "sass-embedded-legacy-load-done:3710";