$component-name: email-subscription;
$email-input-color: $input-color !default;
$email-input-border-color: $input-border-color !default;

.#{$component-name} {
  padding:0rem;
  color:$footer-text-color;

  & &__content {
    max-width: 56.625rem;
    margin: auto;

    &__infos {
      margin-top: 0.25rem;

      p {
        margin: 0;
      }
    }

    &__inputs {
      display: flex;
      align-items: center;

      input[type="email"] {
        margin-right:0rem;
        color: $email-input-color;
        border: 0px solid $email-input-color;
        max-height:35px;
        font-size:$small-font-size;
      }

      .btn-primary {
        color: var(--bs-white);
        border-color: var(--bs-white);
        text-transform: inherit;
      }
    }
  }

  & &__label {
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: var(--bs-white);
  }
}

;@import "sass-embedded-legacy-load-done:3706";