$component-name: customer-link;

.#{$component-name} {
  &__link,
  a {
    display: block;
    text-align:center;

    span {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content:center;
      padding: 1.25rem 1rem;
      font-size: 1rem;
      font-weight: 700;
      color: var(--bs-primary);
      border: 1px solid var(--bs-primary);
      transition: 0.25s ease-out;
      min-height:8.3125rem;

      &:hover {
        color: var(--bs-white);
        background: var(--bs-primary);

        i {
          color: var(--bs-white);
        }
      }

      @include media-breakpoint-down(md) {
        flex-direction: row;
        align-items: center;
        padding: 0.5rem;
      }

      i {
        margin-bottom: 0.75rem;
        font-size: 2.1875rem;
        color: var(--bs-primary);
        transition: 0.25s ease-out;

        @include media-breakpoint-down(md) {
          margin-right: 0.75rem;
          margin-bottom: 0;
        }
      }
    }
  }

  &__logout {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;

    i,
    & {
      color: var(--bs-danger);
    }
  }
}

;@import "sass-embedded-legacy-load-done:3664";