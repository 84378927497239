$component-name: breadcrumb;

.#{$component-name} {
  margin: 0;
}

.breadcrumb__with-cover{
	position:absolute;
	top:0;
	.breadcrumb-item{
		color:$gray-100;	
		a{
			color:$gray-100;	
		}
		&+.breadcrumb-item::before {color:$gray-100;	}
	}
}	

;@import "sass-embedded-legacy-load-done:3637";