$component-name: header-bottom;

.#{$component-name} {
  &__row {
    @include media-breakpoint-up(md) {
      min-height: 4rem;
    }
  }
}

;@import "sass-embedded-legacy-load-done:3611";