//colors

$body-color:#2f2f2f;
$primary:#353A61;
$secondary:#051e38;
$gray-100: #f3f2ee;
$gray-300 : #d9d9d9; //ton de gris de la maquette
$gray-500: #adadad;
$link-color:$primary;
$component-active-bg:$primary;
$light:#f3f2ee;
$input-focus-border-color:tint-color($component-active-bg, 50%);
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);


// theme-colors-map
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);




// Options
$enable-rounded:            false;
$enable-shadows:false;


$paragraph-margin-bottom:   0.5rem;


// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
  xxl:1320px
);

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1201px,
  xxl:1900px
);


$link-decoration:                         null;
$link-hover-decoration:                   underline;
$link-color:                              $secondary;
$link-hover-color:                        $primary;


$border-color:                $gray-300;

$table-striped-bg:            lighten($gray-100, 2%);

/*$table-variants: (
  "primary":    shift-color($primary, $table-bg-scale),
  "secondary":  shift-color($secondary, $table-bg-scale),
  "success":    shift-color($success, $table-bg-scale),
  "info":       shift-color($info, $table-bg-scale),
  "warning":    shift-color($warning, $table-bg-scale),
  "danger":     shift-color($danger, $table-bg-scale),
  "light":      $light,
  "dark":       $secondary,
) ;*/


;@import "sass-embedded-legacy-load-done:3381";