$component-name: product-customization-modal;

.#{$component-name} {
  &__line {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

;@import "sass-embedded-legacy-load-done:3582";