$headings-font-family:'Roboto Slab',$font-family-sans-serif;
$headings-font-weight: 600;
$h1-font-size:                $font-size-base * 1.875;
$h2-font-size:                $font-size-base * 1.75;
$h3-font-size:                $font-size-base * 1.25;
$h4-font-size:                $font-size-base * 1.125;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base * 0.9375;

$headings-color: var(--bs-primary);
$headings-margin-bottom:$spacer;
$headings-line-height:1.3;

// scss-docs-start display-headings
$display-font-sizes: (
  1: 4rem,
  2: 3.5rem,
  3: 3rem,
  4: 2.5rem,
  5: 2rem,
  6: 1.5rem
);
$display-font-weight: 400;


;@import "sass-embedded-legacy-load-done:3406";