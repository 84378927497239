.thumbnails__container {
  margin-top: 1rem;

  .thumbnail {
    &:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  }
}

;@import "sass-embedded-legacy-load-done:3620";