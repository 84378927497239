.quantity-button {
  input {
    max-width: 2.5rem;
    height: 2.375rem;
    padding: 0.375rem 0.25rem;
    text-align: center;
    border-color :$secondary;
    border-width:1px 0px;
    color:$secondary;
  }

  button {
    width: 1.75rem;
    padding: 0;
    border: 1px solid $secondary;

    &.increment {
    	border-left-width:0px;
      .confirmation {
        color: var(--bs-success);
      }
    }

    &.decrement {
    	border-right-width:0px;
      .confirmation {
        color: var(--bs-danger);
      }
    }

    &:hover {
      opacity: 0.6;
    }

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      opacity: 1;
    }

    .material-icons {
      font-size: 1.25rem;
      pointer-events: none;
    }

    .spinner-border {
      border-width: 0.125rem;
    }
  }
}

;@import "sass-embedded-legacy-load-done:3566";