$component-name: quickview;

.#{$component-name} {
  .modal-dialog {
    @include media-breakpoint-only(md) {
      max-width: 750px;
    }
    @include media-breakpoint-only(sm) {
      max-width: 100%;
      margin: 1.75rem;
    }
  }
}

;@import "sass-embedded-legacy-load-done:3583";