$component-name: search;

.search-result {
  margin: 0.5rem 0;

  &__product {
    margin: 0.25rem 0;
  }

  &__link {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: $border-radius;

    &:hover {
      background: var(--bs-gray-100);
    }
  }

  &__name {
    margin-bottom: 0;
    margin-left: 1rem;
    font-size: 1rem;
    color: var(--bs-gray-800);
  }

  &__image {
    max-width: 4rem;
    height: auto;
    border-radius: 8px;
  }
}

.#{$component-name} {
  &__offcanvas {
    bottom: inherit;
    height: auto;
    border-bottom: 0;

    // stylelint-disable-next-line
    #search_widget {
      margin-bottom: 0;
    }

    .offcanvas-header {
      padding: 0.375rem 1rem;

      .btn-close {
        width: auto;
        height: auto;
        margin: 0;
        color: var(--bs-gray-700);
        background: none;
      }
    }
    .btn-expand-open{display:none;}
  }

  &__container {
    width: calc(100% - 0.5rem);
  }

  &-widgets {
    position: relative;
    overflow: visible;

    @include media-breakpoint-down(md) {
      max-width: inherit;
    }

    &__dropdown {
      position: absolute;
      top: calc(100% + 1rem);
      right: 0;
      width: 100vw;
      max-width: 30rem;
      overflow: hidden;
      background: var(--bs-white);
      border: 1px solid $input-border-color;
      border-radius: var(--bs-border-radius-sm);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      @include media-breakpoint-down(md) {
        top: calc(100% + 0.25rem);
        right: inherit;
        left: -1rem;
        max-width: inherit;
        border-radius: 0 0 8px 8px;
      }
    }

    &__results {
      max-height: 20rem;
      padding: 0.5rem 1.5rem 0.5rem 0.5rem;
      margin: 0;
      overflow-y: scroll;
    }
  }
}

.header__bottom,
.#{$component-name}__offcanvas {
  // stylelint-disable-next-line, selector-id-pattern
  #search_widget {
    min-width: 20rem;
    overflow: visible;

    @include media-breakpoint-down(md) {
      min-width: inherit;
    }

    input {
      height: 2.5rem;
      padding-right: 3.5rem;
      padding-left: 1.5rem;
      background: $white;
      border-radius:0rem;

      &:focus {
        outline: 0;
        box-shadow:none;
      }

      @include media-breakpoint-down(md) {
        font-size: 0.9rem;
      }
    }

    .search {
      right: 1.5rem;
      color: var(--bs-gray-700);
    }

    .clear {
      right: 3.5rem;
      color: var(--bs-gray-700);
    }
  }
}
.btn-expand-collapse{
	display:none;
	i,.material-icons{
		color:$body-color;
	}
	&:hover i,&:hover .material-icons{color:$primary;}
}

/***sur desktop***/
.blocksearch{ 
	position:relative;
	@include media-breakpoint-desktop() {
		overflow: hidden;
		&.collapsed{
			overflow: visible;
		}
	}
     
	.btn-expand-open{display:block;}
  .search-widgets {
	  transition: all 0.6s ease-in-out;	
	   position: absolute;
	  left:9000px;
	  z-index: -1;
	  &.collapsed{
	   left: -230px;
	   z-index: 0;
	   &+.btn-expand-open{display:none;}
	   &~.btn-expand-close{display:block;}
    }
   }
   /*input[type="text"]{
   	border-color:$body-color;
   	border-width:0 0 1px;
   	padding-left:0;
   	@include media-breakpoint-up(xl) { 	
   		width:180px;
  	}
  	@include media-breakpoint-up(xxl) { 	
   		width:250px;
  	}

    &:focus{box-shadow:none;}
   }*/
}
;@import "sass-embedded-legacy-load-done:3569";