$component-name: order-confirmation;


.#{$component-name} {
  &__details {
    .order-details {
      margin-bottom: 0;

      li {
        margin-bottom: 0.25rem;
      }
    }
  }

  &__totals > .row:not(:last-child),
  &__subtotals > .row:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__items > .row:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__items .item {
    .item__prices {
      div {
        font-weight: 500;
        color: var(--bs-gray-800);
      }
    }

    .item__reference {
      margin-bottom: 0;
    }

    .item__title {
      margin-bottom: 0;
      font-weight: 600;
      color: var(--bs-gray-800);
    }

    .item__image {
      text-align: center;

      img {
        border-radius: 8px;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:3667";