$component-name: header-top;

.#{$component-name} {
 background-color: var(--header-top-bg);
 text-transform:uppercase;
 font-size:0.875rem;
 font-weight:700;
	a:not(.dropdown-item):not(.btn) {
    color: var(--header-top-color);
    /*&:hover {
      &,
      i {
        color: var(--bs-primary);
      }
    }*/
  }
	.footer__block{
		ul{
			margin:0;
			li{
				display:inline-block;	
				padding: 0 0.5rem;
			}
		}
	}
  &__right {
    > *:not(:last-child) {
      margin-right: 0.25rem;
    }
  }
  
  
}

;@import "sass-embedded-legacy-load-done:3723";