.all-product-link.btn {
  i {
    color: var(--bs-primary);
  }

  &:hover {
    i {
      color: var(--bs-white);
    }
  }
}

.featured-products{
	.products {display:flex;}
	.product-miniature{
		@extend .col-with-padding;	margin-bottom:0.25rem;
	
	}
	.swiper-slide {
      width: 80%;
    }

    .swiper-slide:nth-child(2n) {
      width: 80%;
    }

    .swiper-slide:nth-child(3n) {
      width: 80%;
    }
}
;@import "sass-embedded-legacy-load-done:3699";