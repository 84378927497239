@include media-breakpoint-desktop(){
	.account-form{
		  width: 75%;
	   /*max-width: 450px;*/
	    margin: 0 auto;
	    .register-form &, .login__form-wrapper &{
	    		width: 100%;
	    }
	}
}

#content-wrapper .page-header.page-header-customer{
		margin-bottom:2rem;	
}	

.login__forgot-password{
	text-decoration:underline;
	text-underline-position:under;	
}
;@import "sass-embedded-legacy-load-done:3659";