.contact {
  &__item {
    display: flex;
    padding-bottom: 1rem;

    &:not(:first-of-type) {
      padding-top: 1rem;
    }
  }

  &__info {
    &--email {
      word-break: break-all;
    }
  }

  &__details {
    hr {
      margin: 0;
    }

    i {
      margin-right: 1.25rem;
      color: var(--bs-gray-600);
    }

    h2 {
      margin-bottom: 1.5rem;
    }
  }
}

;@import "sass-embedded-legacy-load-done:3704";