$component-name: search-filters-modules;

.#{$component-name} {
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  border-bottom: var(--category-tree-border-bottom);

  &-title {
    font-size: var(--category-tree-title-size);
    font-weight: 600;
    color: var(--category-tree-title-color);
  }

  &__list {
    margin-bottom: 0;
  }

  &__item {
    &__link {
      display: block;
      font-weight: 600;
      line-height: 2rem;
      color: var(--category-tree-title-color);
    }
  }
}

;@import "sass-embedded-legacy-load-done:3685";